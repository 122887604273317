.headerProfileContainer {
    padding-right: 15px;
    flex-direction: row;
}

.headerProfile {
    padding: 10px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    background-color: rgb(238, 238, 238);
    border-radius: 50%;
}

.hpIconWrap{
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.headerProfile:hover {

}

.headerProfileIcon {
    font-size: 30px;
    color: #00B4CC;
}

.headerProfileDrop {
    position: absolute;
    display: flex;
    width: fit-content;
    flex-direction: column;
    margin-top: 10px;
    margin-left:-160px;
    border: 1px solid rgb(212, 212, 212);
    background-color: white;
    z-index: 1;
    padding: 5px;
    border-radius: 5px;
}

.headerProfileDropItems {
    padding: 15px 15px 15px 15px;
    width: 200px;
    color: rgb(85, 84, 84);
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    
}

.headerProfileDropItems div {

    display: flex;
    flex-direction: row;
    align-items: center;

}

.headerProfileDropItems:hover {
    background-color: rgb(236, 236, 236);
    cursor: pointer;
}
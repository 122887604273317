@import url('https://fonts.googleapis.com/css2?family=Advent+Pro&family=Bangers&family=Heebo:wght@100&family=Luckiest+Guy&family=Montserrat&family=Open+Sans&family=Sigmar+One&display=swap');

:root {
    --themeColor: #00B4CC;
    --themeFont: 'Montserrat', sans-serif;
    --dFontColor: rgb(111, 111, 111);
    --boxShadow: rgb(185, 185, 185);
}

.singlePostContainer {
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    max-width: 850px
}

.singlePostGoBack {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    color: rgb(70, 70, 70);
    padding: 10px
}

.singlePostGoBack:hover {
    cursor: pointer;
    text-decoration: underline 3px rgb(71, 71, 71);

}

.test {
    color: rgb(0, 99, 0)
}

.singlePostPostedToWrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.singlePostPostedTo {
    width: fit-content;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: white;
    color: rgb(70, 70, 70);
    z-index: 3;
    box-shadow: 0 0 10px #ccc;
}

.singlePostItem {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid rgb(220, 220, 220);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    border-radius: 10px;
    background-color: white;
    padding-bottom: 20px;
    color: rgb(50, 50, 50);
    box-shadow: 0 0 30px #ccc;
}

.singlePostTopRight {
    position: relative;
    padding: 10px;
    height: fit-content;
    cursor: pointer; 
}

.singlePostTopIcon {
    display: flex;
    align-self: flex-end;
}

.singlePostProfileImage {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border: 2px solid var(--themeColor);
}

.singlePostCommentsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    padding: 10px 20px 10px 20px;
}

.spcProfileImage {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid var(--themeColor);
}


.commentItemDots {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
}

.commentItemDotsIcon {
    vertical-align: middle;
    margin-right: 5px;
}

.commentItemDotsIcon:hover {
    cursor: pointer;
}


.commentDots:hover {
    cursor: pointer;
}


.dropDownListX {
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid rgb(215, 215, 215);
    list-style-type: none;
    width: auto;
    margin-top: 20px;
    justify-content: reverse;
    z-index: 1;
    padding: 5px;
    box-shadow: 0 0 10px 2px rgb(211, 211, 211);
    /* these below are important 
    to make div dropdown inside post */
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 10px 0px 10px 10px;
}

/*=================================media screen====================================*/
@media screen and (max-width: 768px) {

    .singlePostContainer {
        width: 97%;
        padding: 0px;
        margin-top: 10px;
    }

    .singlePostProfileImage {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid var(--themeColor);
    }

    .singlePostCommentsContainer {
      
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        justify-content: center; 
        background-color: rgb(255, 255, 255);
        margin-top: 20px;
        padding-top: 20px;

    }


    .spcProfileImage {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: none;
        margin-right: 5px;
    }

    .singlePostFormWrap {
        width: 95%;
        padding: 0px;
        display: flex;
        align-self: center;
        justify-self: center;
        margin-bottom: 15px;
        border-radius: 5px;
        background-color: white;
    }

    .singlePostFormWrap form {

        border-radius: 5px;
    }



}

/* =================================    media screen     ==================================== */
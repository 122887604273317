

.mpTopIcon {
    display: flex;
    margin-right: 15px;
    font-size: larger;
    align-items: center;
}

.mpTopName {
    margin-left: 15px;
}


/* width */
::-webkit-scrollbar {
    width: 7px;
}

/* track */
::-webkit-scrollbar-track {
    background: #ffffff;
    margin: 3px;
}

/* handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* handle hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.updateComponentContainer {
    background-color: white;
    border-radius: 2px;
    box-shadow: 2px 2px 2px 2px rgb(182, 181, 181);
    width: 80%;
    height: fit-content;
}

.addImageContainer {
    padding: 25px;
}

.profileImgSettings {
    height: 80px;
    width: 80px;
    border-radius: 80px;
    box-shadow: 0 0 5px 2px rgba(94, 94, 94, 0.6);
    -moz-box-shadow: 0 0 5px 2x rgba(94, 94, 94, 0.6);
    -webkit-box-shadow: 0 0 5px 2px rgba(94, 94, 94, 0.6);
    -o-box-shadow: 0 0 5px 2px rgba(94, 94, 94, 0.6);
}


.updateImageSelectBtn {
    max-width: fit-content;
    display: flex;
    align-items: center;
    padding: 15px;
    color: rgb(56, 56, 56);
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;

}

.updateImageSelectBtn:hover {
    transition: 0.1s;
    cursor: pointer;
    color: var(--themeColor);
    /* border-bottom: 3px solid var(--themeColor); */
}

.cancelImageBtn {
    color: #555;
    font-size: 14px;
    padding: 10px 15px;
}

.cancelImageBtn:hover {
    text-decoration: underline;
    cursor: pointer;
}

.saveImageBtn {
    font-size: 14px;
    padding: 10px 15px;
    background-color: var(--themeColor);
    color: white;
    border-radius: 4px;
}



.saveImageBtn:hover {
    cursor: pointer;
    background-color: rgb(86, 180, 136);
}


.updateFormContainer {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;
}



.settingsUpdateForm {
    width: 75%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    height: 40px;
    border: 1px solid var(--themeColor)
}

.settingsUpdateForm input {
    width: 100%;
}

.passwordSubmitButton {
    width: 70%;
    background-color: #00B4CC;
    border: 3px solid var(--themeColor);
    font-size: 15px;
    color: white;
    cursor: pointer;
    padding: 12px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.passwordWrap {
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: center;
    justify-content: center;
}

.passwordWrap .settingsUpdateForm {
    margin-bottom: 10px;
}

.settingsUpdateForm input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 15px;
    padding: 15px;
    border: none;
    outline: none;
    text-align: left;
    vertical-align: middle;
}

.settingsUpdateButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 101%;
    border: 1px solid var(--themeColor);
    background: var(--themeColor);
    color: #fff;
    cursor: pointer;
    font-size: 15px;
}

.memberships {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.membershipName:hover {
    text-decoration: underline;
}

.membershipName {
    padding-left: 8px;
}

.memberships:hover .membershipsCancel {
    display: block;
}

.leaveBtn {
    padding: 6px;
    background-color: rgb(34, 34, 34);
    color: white;
    font-size: 14px;
    border-radius: 4px;
}

.leaveBtn:hover {
    padding: 6px;
    background-color: red;
    cursor: pointer;
    transition: .3s;
}

.posts {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    gap: 0px;
    margin-bottom: 0;
}

.post {
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    background-color: white; 
    padding: 20px 20px 10px 20px; 
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; 
    border: 1px solid rgb(209, 209, 209);
    border-radius: 10px;
    color: var(--ligthThemeFontColor);
    margin-bottom: 10px; 
    box-sizing: border-box;
}



/* %%%%%%%%%%%%%%%%%%%   post item section   %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */


.postItemTop {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    z-index: 0; 
}


.postUser {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.postInfo {
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 10px;
    top: 10px;
    align-items: center; 
    z-index: 9;
}

.postItemUserImg {
    width: 35px;
    height: 35px;   
}

.postImageImg {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    cursor: pointer;
}

.postItemName {
    padding-left: .5em;
    font-family: 'Roboto', sans-serif;
    color: var(--lightThemeFontColor);
}

.postType {
    color: white;
    margin-left: 20px;
    padding: 3px 6px;
    border: 1px solid rgb(191, 191, 191);
    margin-right: 10px;
}


/* curremntly a placeholder for profile img  */

.postItemMiddle {
    margin-top: 20px; 
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
}

.postImage {
    width: 50%;
    cursor: pointer;
}

.postImageWrap {
    width: 35%;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 10px;
}

@media screen and (max-width: 768px) {
    .postItemDots {
        padding: 0px;
    }

    .postItemDotsIcon {
        margin-right: 0px;
    }

    .postItemDate {
        display: none;
    }

    .postImageWrap {
        width: 100%;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        border-radius: 10px;
    }
}

.postItemImageImg {
    width: 100%;
    height: 150px;
    border-radius: 8px;
    object-fit: cover;
}

.postImage img {
    width: 100%;
}

.postText {
    margin-top: -10px;
    margin-left: 10px;
    font-size: 18px;
    display: flex;
    flex-direction: row;
    word-wrap: break-word;
    width: 80%;
    padding: 10px;
    color: var(--lightThemeFontColor);
}

.postTextNoImage {
    display: flex;
    font-size: 18px;
    color: var(--lightThemeFontColor);
    flex-direction: row;
    word-wrap: break-word;
    width: 80%;
    padding: 0px;
    font-family: 'Roboto', sans-serif;
}


.postDelete {
    border: none;
    background: none;
    float: right;
}

.hide {
    visibility: hidden;
}

.post:hover .hide {
    visibility: visible;
    cursor: pointer;
}



.postItemDate {
    font-size: smaller;
    color: rgb(163, 163, 163);
    margin-right: 20px;
}

.postItemDate2 {
    font-size: smaller;
    color: rgb(163, 163, 163);
    margin-right: 20px;
}



.postItemDots {
    display: flex;
    color: var(--lightThemeFontColor)   ;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 5px;
    z-index: 9;
}

.postItemDotsIcon {
    align-self: center;
    margin-right: 11px;
    font-size: 17px;
}

.postItemDots:hover {
    background-color: rgb(236, 236, 236);
    border-radius: 50%;
}

.postItemBottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: rgb(85, 84, 84);
    justify-content: space-between;
    margin-top: 5px;
    margin-left: 10px;
}

.commentSectionIcon {
    display: flex;
    flex-direction: row;
    margin-right: 20px;
    color: #888080;
    font-size: 12px;
    cursor: pointer;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
}

.commentSectionIcon svg {

    font-size: 17px;
    margin-right: 5px;
}

.commentSectionIcon:hover {
    transition: all .4s ease-in-out;
    background-color: #e9e9e9d7;
}

.commentSectionIconText {
    
    font-weight: 400;
    padding-left: 3px;
}

.postItemActions {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: rgb(85, 84, 84);  
}

.postItemBottomType {}

@media screen and (max-width: 768px) {
    .post {
        max-width: unset;
        width: 100%;
        align-self: center;
        justify-self: center;
        margin-bottom: 10px; 
        margin-left: 0px;  
    }

    .postItemMiddle {
        margin-top: 15px;
        margin-left:  0px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;  
        width: 100%;
    }


    .postItemTop {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-left: 0px;
    }

    .postUser {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .postInfo {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

}
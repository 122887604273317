audio::-webkit-media-controls { 
    width: inherit; 
    position: relative;
    direction: ltr;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

audio {
    width: 100%; 
    border-radius: 0px; 
}

audio::-webkit-media-controls-enclosure {
    width: 100%;
    max-width: 800px; 
    bottom: 0;
    text-indent: 0;
    padding: 0;
    box-sizing: border-box; 
    border-radius: 0px;
}


/* HTML: <div class="loader"></div> */
.soundwave {
    width: 15px;
    aspect-ratio: .75;
    --c: no-repeat linear-gradient(#3f3f3f 0 0);
    background: 
      var(--c) 0%   50%,
      var(--c) 50%  50%,
      var(--c) 100% 50%;
    animation: l7 1s infinite linear alternate;
  }
  @keyframes l7 {
    0%  {background-size: 20% 50% ,20% 50% ,20% 50% }
    20% {background-size: 20% 20% ,20% 50% ,20% 50% }
    40% {background-size: 20% 100%,20% 20% ,20% 50% }
    60% {background-size: 20% 50% ,20% 100%,20% 20% }
    80% {background-size: 20% 50% ,20% 50% ,20% 100%}
    100%{background-size: 20% 50% ,20% 50% ,20% 50% }
  }
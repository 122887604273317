.postSelectDropDown {
    margin-bottom: 0px; 
}


.postFilter p,
.xDotsIcon {
    color: rgb(63, 63, 63);
}

.postFilter:hover {
    background-color: rgb(250, 250, 250);
    cursor: pointer;
}


.postItemSelectLi {
    color: rgb(125, 125, 125);
    display: flex;
    flex-direction: row;
    padding: 10px;
    border-radius: 5px;
    font-size: 12px;
}

.postItemSelectLi:hover {
    cursor: pointer;
    background-color: rgb(238, 238, 238);
}

.postItemSelectLi div {
    color: rgb(98, 98, 98);
    display: flex;
    flex-direction: row;
    padding-left: 15px;
    align-items: center;
}

.postTypeIcons {
    font-size: 20px;
    color: rgb(159, 159, 159);
    margin-right: 10px;
}
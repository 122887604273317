
.searchUsersForm {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: rgb(222, 221, 221);
    padding: 15px;
    border-radius: 35px;

}

.searchUsersInput {
    width: 100%;
}

.searchUsersFormDiv {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}




.searchUsersContainer {
    display: flex;
    flex-direction: column;
}

.searchResultsDropdown {
    color: rgb(90, 90, 90);
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    width: 200px;
    min-height: fit-content;
    max-height: 400px;
    border: 1px solid rgb(192, 192, 192);
    position: absolute;
    background-color: white;
    border-radius: 1px;
}

.searchResultsDropdownItem {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 15px;
}

.searchResultsDropdownItem:hover {
    background-color: rgb(223, 223, 223);
}

.searchUsersInput {

    padding: 10px;
    padding-left: 20px;
    outline: none;
    border-radius: 15px;
    border: none;
    box-shadow: 2px 2px 2px rgb(201, 201, 201);
    margin-right: 5px;
    color: rgb(76, 76, 76);
}

.searchUsersBtntrue {
    flex-basis: 75px;
    flex-shrink: 0;
    height: 35px;
    padding: 10px 0px 10px 0px;
    background-color: rgb(0, 112, 0);
    border: none;
    border-radius: 15px;
    color: white;
}

.searchUsersBtntrue:hover {
    cursor: pointer;
    background-color: rgb(0, 156, 0);
}

.searchUsersBtnfalse {
    flex-basis: 75px;
    flex-shrink: 0;
    height: 35px;
    padding: 10px 0px 10px 0px;
    pointer-events: none;
    border-radius: 15px;
    border: none;
    color: gray;
}

.searchPlaceholder {
    background-color: #eeeeee;
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgb(76, 76, 76);
}

.searchPlaceholderIcon {
    font-size: 45px;
}

/* ================================================================ */


.viewAllItem {
    padding: 15px;
    border-radius: 35px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgb(213, 213, 213);
}

.viewAllItem:hover {
    background-color: rgb(197, 197, 197);
}

.vaiTop {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.vaiSelect {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: var(--ltGreenButton);
    margin-left: 8px;
    border-radius: 8px;
    color: white;
    font-size: x-small;
}

.vaiSelect:hover {
    transition: all 0.4s;
    cursor: pointer;
    background-color: green;
}

.showAll {
    color: gray;
    font-size: smaller;
    text-align: left;
    padding: 5px 0px 0px 5px;
}

.showAll:hover {
    text-decoration: underline;
    cursor: pointer;
}
.alert-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .alert-exit {
    opacity: 1;
  }
  .alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
  

/* becuase of this stupid library  https://reactcommunity.org/react-transition-group/transition-group */
/* ....... well, make your own */

/*  lol */
/* any way.... because of this library, we have to put a classname on the CSSTRANSITIONGROUP, so below "classNames would be "item" basically everything before the first hyphen, yea stupid! */


  .item-enter {
    opacity: 0;
  }
  .item-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  .item-exit {
    opacity: 1;
  }
  .item-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
  }
  
/* 
*
*

TRUE = i am the sender
FALSE = i am not the sender

*/


.mic-true {
    display: flex;
    flex-direction: column;
    align-self: end;
    max-width: 50%;
    background-color: rgb(78, 78, 239);
    border-radius: 15px 15px 0px 15px;
    padding: 15px;
    color: white;
    text-align: left;
    margin-bottom: 10px;
    font-size: 12px;
}

.mic-false {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    max-width: 50%;
    background-color: rgb(58, 171, 122);
    border-radius: 15px 15px 15px 0px;
    padding: 10px;
    color: white;
    text-align: left;
    margin-bottom: 10px;
    font-size: 12px;
}

.mic-true:hover,
.mic-false:hover {
    cursor: pointer;
}


.showMore-true {
    display: flex;
    flex-direction: row;
    align-self: end;
    color: gray;
    font-size: smaller;
    align-items: center;
}

.showMore-false {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    align-self: flex-start;
    color: gray;
    font-size: smaller;
}

.msgOptions {
    margin-left: 10px;
    margin-right: 10px;
}

.msgOptions:hover {
    cursor: pointer;
}


.msgDots-true {
    display: flex;
    flex-direction: column;
    color: gray;
    position: relative;
    background-color: white;
    border: 1px solid rgb(190, 190, 190);
    border-radius: 16px 16px 0px 16px;
    z-index: 1;
    padding: 5px;
    margin-bottom: 5px;
}

.msgDots-false {
    display: flex;
    flex-direction: column;
    color: gray;
    position: relative;
    background-color: white;
    border: 1px solid rgb(190, 190, 190);
    border-radius: 16px 16px 16px 0px;
    z-index: 1;
    padding: 5px;
    margin-bottom: 5px;
}

.msgDelete {
    padding: 10px;
    list-style-type: none;
}

.msgDelete:hover {
    background-color: rgb(228, 228, 228);
    border-radius: 10px 10px 10px 10px;
    cursor: pointer;
}

.msgDelConfirm span {
    display: flex;
    flex-direction: column;
    background-color: rgb(242, 140, 140);
    color: white;
    list-style-type: none;
    padding: 10px;
    border-radius: 10px 10px 10px 10px;
}

.msgDelConfirm span:hover {
    cursor: pointer;
}

.msgDelConfirm {
    list-style-type: none;
}

.msgDelConfirm p {
    color: rgb(99, 99, 99);
    font-size: smaller;
    padding: 5px;

}

.msgDelConfirm p:hover {
    text-decoration: underline;
    cursor: pointer;
}


.directoryImage {
    width: 100%;
    height: 75%;
}

.directoryImage img{
    width: 100%;
    max-height: 100%;
    border-radius: 2px;
}

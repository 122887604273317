.myMembersContainer {
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 700px;
    overflow-y: scroll;
}

.myMembersTop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.membersSearch {
    padding: 10px;
}



.myMembersItemWrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 7px;
    width: 100%;
}

.myMembersItemWrap:hover {
    background-color: rgb(224, 224, 224);
    border-radius: 7px;
}


.mMIInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.reqItemImage{
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.mMIName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 15px;
}

.mMIFullName {
    font-size: 13px;
    color: var(--lightThemeFontColor);

}

.mMIUserName {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    color: rgb(100, 100, 100);
}

.mMIDots {
    color: rgb(85, 85, 85);
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 5px;
    position: relative;
}

/* .mMIDots:hover {
    height: fit-content;
    
} */

.memberDotsWrap {
    /* display: flex;
    flex-direction: column;
    color: gray;
    position: fixed;
    background-color: white;
    border: 1px solid rgb(190, 190, 190);
    border-radius: 0px 16px 16px 16px;
    z-index: 1;
    padding: 5px; */
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid rgb(215, 215, 215);
    list-style-type: none;
    width: auto;
    margin-top: 10px;
    justify-content: reverse;
    z-index: 1;
    padding: 5px;
    box-shadow: 0 0 10px 2px rgb(211, 211, 211);
    /* these below are important 
    to make div dropdown inside post */
    border-radius: 10px 0px 10px 10px;
    position: absolute;
    top: 10px;
    right: 15px;
}


.memberViewProfile {
    padding: 10px;
    border-radius: 3px;
}

.memberViewProfile:hover {
    background-color: rgb(228, 228, 228);
    border-radius: 0px 10px 0px 0px;
}

.memberMenuItem {
    /* padding: 10px;
    list-style-type: none; */
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    padding: 5px 10px;
    color: rgb(103, 103, 103);
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}

.memberMenuItem:hover {
    background-color: rgb(228, 228, 228);
    
}

.memberRemoveConfirm span {
    display: flex;
    flex-direction: column;
    background-color: rgb(242, 140, 140);
    color: white;
    list-style-type: none;
    padding: 10px;
    border-radius: 0px 10px 10px 10px;
}

.memberRemoveConfirm {
    list-style-type: none;
}

.memberRemoveConfirm p {
    color: rgb(99, 99, 99);
    font-size: smaller;
    padding: 5px;

}

.memberRemoveConfirm p:hover {
    text-decoration: underline;
}

.leaveChurch{
    color: var(--lightThemeFontColor);
    font-size: 11px;
    padding: 5px;
    cursor: pointer;
    white-space: nowrap;
}
.leaveChurch:hover {
    background-color: #eeeeee;
    border-radius: 6px;
}
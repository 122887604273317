 @import url('https://fonts.googleapis.com/css2?family=Advent+Pro&family=Bangers&family=Heebo:wght@100&family=Luckiest+Guy&family=Montserrat&family=Open+Sans&family=Sigmar+One&display=swap');

:root {
  --themeColor: #00B4CC;

  --themeColorLight: #04d0eb;
  --themeColorDark: #008da0d2;
  --themeFont: 'Roboto', sans-serif;
  --dFontColor: rgb(102, 102, 102);
  --itemBackgroundColor: #f9f9f986;
  --borderColor: rgb(200, 200, 200);
  --fontColorDarkGrey: rgb(119, 119, 119);
  --themeBGColor: #eeeeee;
  --btnSubmit: #1a73e8;
  --componentButtonHoverColor: rgb(245, 245, 245);

  /* we will begin to set standards for theming. First we will set up light theme. */
  --lightThemeFontColor: #727575;
  --themeColorBtnText: #008395;
  --lightThemeSecondaryFontColor: #9c9c9c;

  /* buttons */
  --ltGreenButton: #46801fac;
  --ltGreenButtonSolid: #46801f;

}

.leaflet-container {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 
}

body {
  /* width: 100vw; */
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  height: 100vh;
  background-color: #eeeeee;
}

a {
  text-decoration: none;
  color: #000;
}

p {
  line-height: 1.7;
}

ul {
  list-style: none;

}

li {
  line-height: 2.2;
}


.container {
  width: 100%;
  /* max-width: 1360px; */
  margin: 0 auto;
  padding: 0;
  text-align: center;

  overflow-x: hidden; 

}

.header {
  z-index: 19;
  height:55px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px;
  margin-bottom: 0px;
  background-color: white;
  width: 100%;


  /* box-shadow: 3px 3px 7px rgb(179, 179, 179); */
}



.header ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 9;
}

.header ul li {
  margin-left: 20px;
}

.header ul li a {
  display: flex;
  align-items: center;
}

.header ul li a:hover {
  color: #777;
}

.header ul li a svg {
  margin-right: 5px;
}

.heading {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 50px;
  padding: 0 20px;
}

.heading p {
  color: #828282;
}

.headerLeft {
  display: flex;
  flex-direction: row;
  align-items: center;

}

.headerRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}


.logo {
  height: 100%;
}

.prayselyLogoImage {
  width: 100px;
  height: auto; 
}

@media (max-width: 768px) {

  .prayselyLogo{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

  }

  .prayselyLogoImage {
    width: 55px;
    height: auto;
  }
}


.homeContainer {
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
  margin: 0;
}

.usrBtn {
  width: 100px;
  height: 30px;
  border-radius: 30px;
  border: none;
  background-color: #0cb0c5a8;
  color: white;
}

.usrBtn:hover {
  cursor: pointer;
  background-color: #008da0d2;
  transition: 0.5s;
}

.form,
.content {
  width: 100%;
  margin: 0 auto;
}

.loginForm {
  width: 400px;
}

.form-group {
  margin-bottom: 10px;
  padding-left: 2em;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: inherit;
}

.form-group label {
  text-align: left;
  display: block;
  margin: 0 0 5px 3px;
}

.btn {
  padding: 10px 20px;
  border: 1px solid var(--themeColor);
  border-radius: 5px;
  background: var(--themeColor);
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  appearance: button;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn svg {
  margin-right: 8px;
}

.btn-reverse {
  background: #fff;
  color: #000;
}

.btn-block {
  width: 100%;
  margin-bottom: 20px;
}

.btn:hover {
  transform: scale(0.98);
}

.profileContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .form {
    width: 90%;
  }

  .heading h1 {
    font-size: 2rem;
  }

  .heading p {
    font-size: 1.5rem;
  }
}

@media (max-width: 900px) {


  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

  }

  body {
    font-family: 'Poppins', sans-serif;

    height: 100vh;
  }

  a {
    text-decoration: none;
    color: #000;
  }

  p {
    line-height: 1.7;
  }

  ul {
    list-style: none;
  }

  li {
    line-height: 2.2;
  }

  h1,
  h2,
  h3 {
    font-weight: 600;
    margin-bottom: 10px;
  }

  .container {
    width: 100%;
    max-width: 1360px;
    margin: 0 auto;
    padding: 0;
    text-align: center;
  }

  .header {
    width: 100%;
    height:60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
  }

  @media (max-width: 768px) {
    .header {
      height: 55px;
    }
  }


  .header ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header ul li {
    margin-left: 20px;
  }

  .header ul li a {
    display: flex;
    align-items: center;
  }

  .header ul li a:hover {
    color: #777;
  }

  .header ul li a svg {
    margin-right: 5px;
  }

  .heading {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 0px;
    padding: 0 20px;
  }

  .heading p {
    color: #828282;
  }

  .logo img {
    width: 105px;
    height: auto;
  }

  .postItemTop {
    display: inline;
    padding-left: .9em;
    padding-right: .9em;
  }

  .postItemImg {
    float: left;
    width: 25px;
    height: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    border: 1px solid rgb(177, 172, 172);
    font-weight: bolder;
  }

  .postImageImg {
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }


  .postItemName {
    float: left;
    padding-left: .5em;
    font-size: 15px;
  }

  .postText {
    display: flex;
    word-wrap: break-word;
    font-size: 15px;
    width: 100%;
    margin: auto;
    text-align: left;
    padding-bottom: 5px;
  }

  .postDelete {
    border: none;
    background: none;
    float: right;
  }

  .hide {
    visibility: hidden;
  }

  .commentText {
    display: flex;
    justify-content: flex-start;

  }

  .usrBtn {
    width: 70px;
    height: 30px;
    border-radius: 30px;
    border: none;
    background-color: #0cb0c5a8;
    color: white;
  }

  .usrBtn:hover {
    cursor: pointer;
    background-color: #008da0d2;
    transition: 0.5s;
  }

  .form,
  .content {
    width: 100%;
    margin: 0 auto;
    display: block;
  }

  .loginForm {
    width: 400px;
  }

  .form-group {
    margin-bottom: 10px;
    padding-left: 2em;
  }

  .form-group input,
  .form-group textarea,
  .form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    margin-bottom: 10px;
    font-family: inherit;
  }

  .form-group label {
    text-align: left;
    display: block;
    margin: 0 0 5px 3px;
  }

}
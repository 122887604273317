.sendMessageBtnContainer {
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-right: 10px;
    border-radius: 50%;
    box-shadow: 2px 2px 2px rgb(199, 199, 199);
}

.sendMessageBtnIcon {
    font-size: 30px;
    font-weight: lighter;
    color: rgb(174, 174, 174);
}

.sendMessageBtnContainer:hover .sendMessageBtnIcon {
    color: white;
    cursor: pointer;
}


.sendMessageBtnContainer:hover {
    cursor: pointer;
    background-color: #0cb0c5a8;
}


.requestBtns {
    display: flex;
    flex-direction: row;
}

.btnAccept {
    padding: 10px 20px;
    background-color: green;
    color: white;
    border-radius: 5px;
    font-size: smaller;
}

.btnAccept:hover {
    background-color: rgb(4, 165, 4);
    cursor: pointer;
    transition: .4s;
}

.btnReject {
    padding: 10px;
    background-color: rgb(146, 146, 146);
    color: white;
    border-radius: 5px;
    font-size: smaller;
    margin-left: 3px;
}

.btnReject:hover {
    background-color: rgb(119, 119, 119);
    cursor: pointer;
    transition: .4s;
}

.reqTooltip {
    position: relative;
    display: inline-block;
}

.reqTooltip .reqTooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: rgb(75, 75, 75);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -60px;
}

.reqTooltip .reqTooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgb(75, 75, 75) transparent transparent transparent;
}

.reqTooltip:hover .reqTooltiptext {
    visibility: visible;
}

.noRequestsWrap {
    display: flex;
    flex-direction: column;
    color: rgb(112, 112, 112);
}

.noRequestsIcon {
    font-size: 60px;
}
body {
    margin: 0;
    padding: 0;
  }

  .cardInput {
    border: 1px solid #ccc;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    height: 50px;   
    outline: none;
  }
  
  .CropApp {
    position: absolute;
    width: 15vw;
    top: 0;
    right: 0;
    bottom: 0;
  }
  
  .crop-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 80px;
  }
  
  .controls {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 50%;
    transform: translateX(-50%);
    height: 80px;
    display: flex;
    align-items: center;
  }
  
  .slider {
    padding: 22px 0px;
  }
  
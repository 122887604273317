.profileRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.profileRowChurch{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}


.pageContainer{
    /* border: 1px solid green; */
    display: flex;
    overflow: hidden;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start; 
    margin: 0 auto;
    height: calc(100vh - 60px);
}

@media screen and (max-width: 760px) {
    .pageContainer {
        width: 100%;
    }
  }

.userProfileMain{
    margin-left: 0px;
    width: 100%;
    display: flex; 
    flex-direction: column;  
}

.userProfileMainUser{
    margin-left: 0px;
    padding: 15px;
    width: 100%;
    display: flex;
    flex-direction: column; 
}


.userProfileTopContainer {
    display: flex;
    flex-direction: column;
}

.churchProfileLeft{
    display: flex;
    flex-direction: column;
}

.profileColumnLeft {
    display: flex;
    width: fit-content; 
    height: auto;
    justify-content: center;
}

.profileColumnRight {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: auto;
    align-self: flex-start;
    justify-content: center;
}

.noPosts{
    color: gray;
    padding: 25px;
    width: 100%;
    border: 1px solid rgb(165, 165, 165);
}


.viewMembership{
    display: flex;
    flex-direction: row;
    padding: 10px 10px;
    color: var(--fontColor);
    align-items: center;
    justify-content: center;
}

.notifNumber {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgb(255, 0, 0);
    width: fit-content  ;
    height: fit-content;

}

.extrasItem{
    padding: 5px 20px;
    color:rgb(121, 121, 121);
    font-weight: 500;
    font-size: 19px;
    transition: all 0.4s;
    width: fit-content;
}   